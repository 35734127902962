import { TableSortLabel } from "@material-ui/core"
import * as React from "react"


export interface HeaderProps {
    classes: any,
    columns: any,
    orderBy: string,
    order: string,
    isSorted: boolean,
    sortHandler: any
}


export const HebridesTableHeader = (headerProps: HeaderProps) => {

    const {classes, columns, orderBy, order, isSorted, sortHandler} : HeaderProps = headerProps

    const defaultColStyle = { padding: 15, background: "rgba(255,255,255,0.45)", borderBottom: "1px solid grey", whiteSpace: "nowrap" }

    const CreateSortedColumn = (column : any) => {

        const direction : any = orderBy === column.id ? order : "asc"

        return(    
            <th
                key={ column.id }
                style={ column.style ? column.style : defaultColStyle }
            >
                <TableSortLabel
                    active={orderBy === column.id}
                    onClick={sortHandler(column.id)}
                    direction={direction}
                >
                {column.label}
                {orderBy === column.id && (
                    <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                )}
                </TableSortLabel>
            </th>
        )
    }

    const CreateColumn = (column : any) => {
        return(
            <th key={ column.id } style={ column.style ? column.style : defaultColStyle }>
            {column.label}
            </th>
        )
    }

    const columnElements = isSorted 
                            ? columns.map((column : any) => CreateSortedColumn(column)) 
                            : columns.map((column : any) => CreateColumn(column))


    return (
        <thead className="dashboard-thead">
            <tr>
                {columnElements}       
            </tr>
        </thead>
    )
}

export default HebridesTableHeader