import React from "react"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "../Telemetry/appInsights"

export const AppInsightsContextProvider = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  )
}
