import React, { useState, useEffect } from "react"

import moment from "moment-timezone"
import ReactPaginate from "react-paginate"

import { ModalContext } from "../../Contexts/ModalContext"
import { callHebridesApi } from "../../Helpers/fetch"
import { TaskLogModal } from "../../Components/Tasks/TaskLogModal"
import { StyledLinearProgress } from "../StyledLoader"
import { ErrorBoundary } from "../ErrorBoundary"

const REFRESH_EVENT_NAME = 'TaskQueue:refresh'

/**
 * Dispatches a document event to request any mounted TaskQueue component to refresh its table of tasks.
 * Run this after queueing up a new task (e.g. Schedule SOE Upgrade) so the table is refreshed immediately.
 */
export const dispatchTaskQueueRefresh = () => {
  // https://www.falldowngoboone.com/blog/talk-to-your-react-components-with-custom-events/
  document.dispatchEvent(new Event(REFRESH_EVENT_NAME))
}

export const TaskQueue = (props) => {
  const [taskStatus, setTaskStatus] = useState(null)
  const [loadState, setLoadState] = useState({
    loading: true,
    status: null,
  })
  const [taskLogId, setTaskLogId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentpage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const [currentItems, setCurrentItems] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [itemOffset, setItemOffset] = useState(0)
  const [taskPages, setTaskPages] = useState(1)

  const api = `${props.api}&page=${currentpage}&size=${pageSize}`
  const route = window.location.pathname

  useEffect(() => {
    const refreshTasks = () =>
      callHebridesApi("GET", `${api}&size=50`, "")
        .then((response) => { setTaskStatus(response.data); return response })

    // Only let the task list do refreshes when a task modal is not open
    if (!showModal) {
      refreshTasks()
        .then((response) => {
          setLoadState((prev) => ({
            ...prev,
            loading: false,
            status: response.status,
          }))
        })

      // Refresh the list every 30 seconds
      const intervalId = setInterval(refreshTasks, 30000)

      // Also listen to a custom event so the parent page can trigger a refresh directly
      document.addEventListener(REFRESH_EVENT_NAME, refreshTasks)

      return () => {
        clearInterval(intervalId)
        document.removeEventListener(REFRESH_EVENT_NAME, refreshTasks)
      }
    }
  }, [showModal, api])

  useEffect(() => {
    if (taskStatus) {
      const endOffset = itemOffset + itemsPerPage
      setCurrentItems(taskStatus.slice(itemOffset, endOffset))
      setTaskPages(Math.ceil(taskStatus.length / itemsPerPage))
    }
  }, [itemOffset, itemsPerPage, taskStatus])

  const handlePageChange = (evt) => {
    const newOffset = loadState.loading ? 0 : (evt.selected * itemsPerPage) % taskStatus.length
    setItemOffset(newOffset)
  }

  const handleCancel = (evt) => {
    const taskToCancel = {
      TaskId: evt.target.id
    }
    callHebridesApi("POST", "/v2/infra/upgrade/cancel", taskToCancel)
    evt.preventDefault()
  }

  const handleRetry = (evt) => {
    const taskToRetry = {
      TaskId: evt.target.id,
    }
    const task = taskStatus.filter((t) => t.servertaskId === evt.target.id)[0]
    callHebridesApi(
      "POST",
      `/v2/build-retry/${task.client}/${task.environment}`,
      taskToRetry
    )
    evt.preventDefault()
  }

  const handleViewLog = () => {
    setShowModal((state) => !state)
  }

  const tableRows = currentItems
    ? Object.entries(currentItems).map((entry) => {
        return (
          <tr key={entry[0]}>
            <td>
              {moment(entry[1].deploymentTime)
                .tz("America/New_York")
                .format("MM/DD/YYYY h:mma")}
            </td>
            <td>{entry[1].client}</td>
            <td>{entry[1].environment}</td>
            {route.includes("upgrade") ? <td>{entry[1].version}</td> : null}
            <td>{entry[1].result}</td>
            <td className="ta-center">
              <button
                type="button"
                className="s-btn s-btn__primary"
                onClick={() => {
                  setTaskLogId(entry[1].servertaskId)
                  handleViewLog()
                }}
              >
                View Log
              </button>
              {["/build"].includes(route) ? (
                entry[1].result === "Failed" ? (
                  <button
                    type="button"
                    id={entry[1].servertaskId}
                    className="s-btn s-btn__primary"
                    onClick={handleRetry}
                  >
                    Retry
                  </button>
                ) : null
              ) : null}
              {
                // Service Upgrades are the only safe task to cancel.
                // Cancelling TF execution results is bad state.
                route.includes("upgrade") ? (
                  ["Executing", "Queued"].includes(entry[1].result) ? (
                    <button
                      type="button"
                      className="s-btn s-btn__primary"
                      id={entry[1].servertaskId}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="s-btn s-btn__primary"
                      style={{
                        borderColor: "lightgrey",
                        backgroundColor: "lightgrey",
                      }}
                      disabled
                    >
                      Cancel
                    </button>
                  )
                ) : null
              }
            </td>
          </tr>
        )
      })
    : null

  return loadState.loading ? (
    <StyledLinearProgress />
  ) : (
    <ErrorBoundary>
      <table className="s-table">
        <thead>
          <tr>
            <th data-sortable="true" data-field="time">
              Start Time (ET)
            </th>
            <th data-sortable="true" data-field="client">
              Client
            </th>
            <th data-sortable="true" data-field="env">
              Environment
            </th>
            {route.includes("upgrade") ? (
              <th data-sortable="true" data-field="version">
                Version
              </th>
            ) : null}
            <th data-sortable="true" data-field="status">
              Status
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {taskLogId ? (
            <ModalContext.Provider
              value={{ showModal, taskLogId, handleViewLog }}
            >
              <TaskLogModal />
            </ModalContext.Provider>
          ) : null}
          {tableRows}
        </tbody>
      </table>
      <ReactPaginate
        pageCount={taskPages}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        nextLabel={"Next"}
        nextClassName="s-pagination--item"
        previousLabel={"Prev"}
        previousClassName="s-pagination--item"
        pageClassName="s-pagination--item"
        containerClassName="s-pagination"
        activeClassName="s-pagination--item is-selected"
        renderOnZeroPageCount={null}
      />
    </ErrorBoundary>
  )
}
