import * as React from 'react'

interface IStatusLabel {
    taskState: string
}

export const StatusLabel = ({ taskState } : IStatusLabel) => {
    const icons: Record<string, object> = {
        canceled: <span className="s-badge bg-black-50 bc-black-600 fc-black-800">Canceled</span>,
        cancelling: <span className="s-badge bg-yellow-100 bc-yellow-600 fc-yellow-900">Cancelling</span>,
        executing: <span className="s-badge bg-yellow-100 bc-yellow-600 fc-yellow-900">Executing</span>,
        failed: <span className="s-badge bg-red-100 bc-red-600 fc-red-900 ">Failed</span>,
        queued: <span className="s-badge bg-blue-100 bc-blue-600 fc-blue-900">Queued</span>,
        success: <span className="s-badge bg-green-100 bc-green-600 fc-green-900">Success</span>,
        timedout: <span className="s-badge bg-orange-100 bc-orange-600 fc-orange-900">Timed out</span>,
    }

    return (icons[taskState?.toLowerCase()])
}

export default StatusLabel;