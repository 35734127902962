import React from "react"
import NavMenu from "./Nav"
import { AuthenticatedTemplate } from "@azure/msal-react"

export const Sidebar = () => {
  return (
    <div className="app-sidebar sidebar-shadow">
      <div className="app-sidebar__inner">
        <AuthenticatedTemplate>
          <NavMenu />
        </AuthenticatedTemplate>
      </div>
    </div>
  )
}
