{
  "apiHost": "https://hebrides-backend.stackenterprise.co",
  "AppInsights": {
    "BackendHost": "hebrides-backend.stackenterprise.co",
    "InstrumentationKey": "73bd9691-c663-409a-94da-2ce336097732"
  },
  "UserAuth": {
    "TenantId": "5ecec5bd-089d-4d8b-b2c1-5024715d6e42",
    "ClientId": "89ba2116-765b-49a3-93cc-ee140ea5b185",
    "SpecialAccessGroups": [
      "f52ab712-62b6-4d6d-ac26-3e65b0ea9bc5"
    ]
  }
}
