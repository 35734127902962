import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"

import { Sidebar } from "../../Components/Sidebar/Sidebar"
import { Header } from "../../Components/Header/Header"
import { Footer } from "../../Components/Footer"
import { ErrorBoundary } from "../../Components/ErrorBoundary"
import { SidebarContextProvider } from "../../Contexts/SidebarContext"

const MainContent = (props) => {
  return (
    <SidebarContextProvider>
      <Router>
        <div className="d-flex fd-column app-container fixed-sidebar fixed-header">
          <Header />
          <div className="app-main">
            <Sidebar />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">Hebrides</div>
                  </div>
                  <div className="page-title-subheading">
                    Stack Overflow Enterprise Hosted Management
                  </div>
                </div>
                <ErrorBoundary>
                  <div>{props.children}</div>
                </ErrorBoundary>
              </div>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </Router>
    </SidebarContextProvider>
  )
}

export default MainContent
