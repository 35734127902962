import React from "react"
import ReactDOM from "react-dom"

//MSAL Components
import { MsalProvider } from "@azure/msal-react"
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./Components/Auth/AuthConfig"

//Import components
import "@stackoverflow/stacks/dist/css/stacks.min.css"
import "./assets/styles/main.css"
import Main from "./App"

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

const rootElement = document.getElementById("root")
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Main />
  </MsalProvider>,
  rootElement
)

// This define can be toggled in the script that calls esbuild --watch to
// determine whether to reload the page when scripts are edited or not
// https://esbuild.github.io/api/#live-reload
if (window.HEBRIDES_HOT_RELOAD) {
  new EventSource('/esbuild').addEventListener('change', ev => {
    const { added, removed, updated } = JSON.parse(ev.data)

    // This if block handles cases where it's possible to reload CSS without reloading the page
    // https://esbuild.github.io/api/#hot-reloading-css
    const updatedExcludingCssSourceMaps = updated.filter(f => !f.endsWith('.css.map'))
    if (!added.length && !removed.length && updatedExcludingCssSourceMaps.length === 1) {
      for (const link of document.getElementsByTagName("link")) {
        const url = new URL(link.href)

        if (url.host === location.host && url.pathname === updatedExcludingCssSourceMaps[0]) {
          const next = link.cloneNode()
          next.href = updatedExcludingCssSourceMaps[0] + '?' + Math.random().toString(36).slice(2)
          next.onload = () => link.remove()
          link.parentNode.insertBefore(next, link.nextSibling)
          return
        }
      }
    }

    // Fall back to reloading the page if it's not just a CSS file change
    location.reload()
  })
}
