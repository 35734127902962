import { useState } from "react"
import * as React from "react"
import { HebridesTableHeader, HeaderProps } from "./HebridesTableHeader"
import {HebridesPaginationFooter, PaginationProps } from "./HebridesPaginationFooter"

interface TableProps  {
    Row: any,
    items: any,
    headerProps: {classes: any,  columns: any, isSorted: boolean},
    isPaginated: Boolean,
    initialRowCount: number,
    paginationProps?: {
        rowsPerPageOptions: (number | { label: string, value: number })[], 
        colSpan: number, 
        footerStyle: any
    }
}

export const HebridesTable = (
    tableProps: TableProps
)  => {
    const {Row, items, initialRowCount} : TableProps = tableProps

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(initialRowCount)
    const [orderBy, setOrderBy] = useState("client")
    const [order, setOrder] = useState("asc")


    const handleChangePage = (newPage: any) => {
        setPage(newPage)
    }
    
    const handleChangeRowsPerPage = (event : any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const sortHandler : any = (property: any) => () => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const descendingComparator = (firstItem: any, secondItem: any, orderBy: string) => {
        if (secondItem[orderBy] < firstItem[orderBy]) {
          return -1
        }
        if (secondItem[orderBy] > firstItem[orderBy]) {
          return 1
        }
        return 0
    }
      
    const getComparator = (order: string, orderBy: string) => {
        return order === "desc"
          ? (firstItem: any, secondItem: any) => descendingComparator(firstItem, secondItem, orderBy)
          : (firstItem: any, secondItem: any) => -descendingComparator(firstItem, secondItem, orderBy)
    }
      
    const stableSort = (array: [], comparator: any) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0])
          if (order !== 0) return order
          return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
      }

    const rowData = tableProps.isPaginated 
                    ? stableSort(items, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : items
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const headerProps = {
        classes: tableProps.headerProps.classes,
        columns: tableProps.headerProps.columns,
        orderBy,
        order,
        isSorted: tableProps.headerProps.isSorted,
        sortHandler
    }

    const paginationProps = tableProps.isPaginated && tableProps.paginationProps ? {
        rowsPerPageOptions: tableProps.paginationProps.rowsPerPageOptions,
        colSpan: tableProps.paginationProps.colSpan,
        count: items.length,
        page,
        rowsPerPage,
        footerStyle: tableProps.paginationProps.footerStyle,
        handleChangePage,
        handleChangeRowsPerPage
    } : null

    return(
        <table
        className="s-table s-table__stripes dashboard-table"
        aria-label="collapsible table"
        >
            <HebridesTableHeader {...headerProps}/>
            <tbody>
                {rowData.map((item: any, index: any) => <Row key={index} row={item}/>)}
            </tbody>
            {tableProps.isPaginated && 
            paginationProps && 
            <HebridesPaginationFooter {...paginationProps} />
            }
        </table>   
    )
}

export default HebridesTable