import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js"
import { createBrowserHistory } from "history"

// staticConfig.json should be populated by generateStaticConfig.js, which runs in the CI/CD pipeline as a prebuild script
import staticConfig from'../staticConfig.json'

// import axios from "axios"

// const key = axios.get('/api/settings').then(response => {
//   return response.data.appInsightsKey
// })

const browserHistory = createBrowserHistory({ basename: "" })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: staticConfig.AppInsights.InstrumentationKey,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    // only add correlation headers to front end and back end requests (not third party requests)
    correlationHeaderDomains: [
      location.host,
      staticConfig.AppInsights.BackendHost,
    ],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})

appInsights.loadAppInsights()
export { appInsights, reactPlugin }
