import axios from "axios"
import { msalInstance } from ".."
import { loginRequest } from "../Components/Auth/AuthConfig"

// staticConfig.json should be populated by generateStaticConfig.js, which runs in the CI/CD pipeline as a prebuild script
import staticConfig from'../staticConfig.json'

export const callHebridesApi = async (apiMethod, uri, apiData) => {
  const accounts = msalInstance.getAllAccounts()
  let accessToken
  if (accounts.length > 0) {
    const account = msalInstance.setActiveAccount(accounts[0])
    accessToken = await msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then((response) => response.accessToken)
  }
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json; charset=utf-8",
  }

  return await axios({
    url: `${staticConfig.apiHost}/api${uri}`,
    method: apiMethod,
    headers: headers,
    cache: "no-cache",
    timeout: 0,
    data: apiData,
  });
}
