import React, { useState, useEffect } from "react"
import { ErrorBoundary } from "../../Components/ErrorBoundary"
import { ErrorNotice } from "../../Components/Common/ErrorNotice"
import { ApiResponse } from "../../Components/APIResponse"
import { Layout } from "../../Components/PageLayout"
import { StyledLinearProgress } from "../../Components/StyledLoader"
import { TaskQueue, dispatchTaskQueueRefresh } from "../../Components/Tasks/TaskQueue"
import { callHebridesApi } from "../../Helpers/fetch"
import { GetCustomersByEnv } from "./Actions"
import { useDeploymentEnvironments } from "../../Helpers/useDeploymentEnvironments"

export const Decomm = () => {
  const [environment, setEnvironment] = useState(null)
  const [customerRSGs, setcustomerRSGs] = useState([])
  const [apiResponse, setAPIResponse] = useState(null)
  const [decommData, setDecommData] = useState(null)
  const environments = useDeploymentEnvironments()

  useEffect(() => {
    if (environment) {
      GetCustomersByEnv(environment).then((data) => setcustomerRSGs(data))
    }
  }, [environment])

  const handleChange = (evt) => {
    const decommTarget = evt.target.value.split(" - ")
    var decomm = {
        Client: decommTarget[0],
        Environment: environment,
        Ticker: decommTarget[1].split("(")[1].slice(0, -7),
    }
    setDecommData(decomm)
  }

  const submitDecomm = (evt) => {
    evt.preventDefault()
    callHebridesApi(
      "POST",
      `/v2/infra/decomm/${environment}/${decommData.Client}`,
      ""
    ).then((response) => {
      setAPIResponse(response)
      dispatchTaskQueueRefresh()
    })
    setDecommData(null)
  }

  return (
    <>
      <Layout title="Decommission SOE Customer">
        { environments.state === 'ERROR' && <ErrorNotice /> }
        { environments.state === 'LOADING' && <StyledLinearProgress /> }
        { environments.state === 'OK' && (
          <form>
            <label className="control-label">Environment</label>
            <div className="d-flex form-group">
              <select
                className="form-control"
                id="env"
                defaultValue={environment}
                onChange={(e) => setEnvironment(e.target.value)}
              >
                <option value="_blank"></option>
                { Object.entries(environments.data).map(([value, label]) => <option key={value} value={value}>{label}</option>) }
              </select>
            </div>
            <div className="d-flex form-group">
              <input
                type="file"
                id="file-input"
                multiple
                style={{ display: "none" }}
              />
              <select
                className="form-control"
                id="rsg-list"
                multiple
                size={10}
                onChange={handleChange}
              >
                {customerRSGs
                  ? customerRSGs.map((o, index) => {
                      return <option key={index}>{o}</option>
                    })
                  : null}
              </select>
            </div>
            <hr />
            <div className="d-flex">
              <div className="flex--item3">
                <button
                  className="s-btn s-btn__primary"
                  id="submit"
                  type="submit"
                  onClick={submitDecomm}
                >
                  Submit
                </button>
              </div>
              <div className="flex--item3">
                <div>
                  {apiResponse ? <ApiResponse response={apiResponse} /> : null}
                </div>
              </div>
            </div>
          </form>
        )}
      </Layout>
      <Layout title="Decomm Queue (Last 10)">
        <ErrorBoundary>
          <TaskQueue api="/v2/infra/tasks/state?tasktype=runbookrun&runbook=DecommissionCustomer" />
        </ErrorBoundary>
      </Layout>
    </>
  )
}
