import React, { useState, useEffect, useContext } from "react"
import { JsonForms } from "@jsonforms/react"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers"

import {
  configschema,
  hadrjson,
} from "../../Components/ConfigSchema/configschema"
import { uischema } from "./UiSchema/Schema"
import { ApiResponse } from "../../Components/APIResponse"
import { Layout } from "../../Components/PageLayout"
import { CertModal } from "../../Components/CertModal"
import { callHebridesApi } from "../../Helpers/fetch"
import { GetTaskQueue } from "../../Components/Tasks/TaskQueue"
import { UserMemberOfContext } from "../../Contexts/UserContext"
import { ErrorNotice } from "../../Components/Common/ErrorNotice"
import { StyledLinearProgress } from "../../Components/StyledLoader"
import { useDeploymentEnvironments } from "../../Helpers/useDeploymentEnvironments"
import { azRegionPairs } from "../../Helpers/azRegionPairs"
import staticConfig from '../../staticConfig.json'

export const Modify = () => {
  const userMemberOfContext = useContext(UserMemberOfContext)
  const hasMembershipData = !!userMemberOfContext.data
  if (!hasMembershipData) {
    return null
  }

  // userMemberOfContext is an Axios response for a call to this endpoint: https://learn.microsoft.com/en-us/graph/api/user-list-memberof?view=graph-rest-1.0&tabs=http
  // userMemberOfContext.data.value is therefore an array of these objects: https://learn.microsoft.com/en-us/graph/api/resources/directoryobject?view=graph-rest-1.0
  const hasSpecialAccess = userMemberOfContext.data.value.some(
    directoryObject => (staticConfig.UserAuth.SpecialAccessGroups || []).includes(directoryObject.id)
  );

  const [customerData, setCustomerData] = useState(null)
  const [customerOptions, setCustomerOptions] = useState(null)
  const [selectedEnv, setSelectedEnv] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState("_blank")
  const [apiResponse, setApiResponse] = useState(null)
  const [hadr, setHADR] = useState(false)
  const [cert, setCert] = useState(false)
  const [certModal, setCertModal] = useState(false)
  const environments = useDeploymentEnvironments()

  const handleEnvSelector = (e) => {
    setSelectedEnv(e.target.value)
    setCustomerData(null)
    setSelectedCustomer("_blank")
  }

  const handleModal = (newData) => {
    setCustomerData((prev) => ({
      ...prev,
      ...newData,
    }))
  }

  const handleCustomerSelector = (e) => {
    setCustomerData(null)
    setSelectedCustomer(e.target.value)
  }

  const handleHadrSelector = (e) => {
    setHADR((state) => !state)
  }

  const handleSSLSelector = (e) => {
    setCert((state) => !state)
  }

  //Side effect to handle HADR toggle.
  //Needs separate function component and ErrorBoundary
  useEffect(() => {
    if (customerData) {
      //If HADR toggle == true, get the next available VLAN for the secondary region
      //and add the HADR components to the config...else if false...
      if (hadr && "hadr" in customerData.locals) {
        callHebridesApi(
          "GET",
          `/v2/infra/vlans?operation=getnextavailable&offset=0`,
          ""
        ).then((response) => {
          setCustomerData((prev) => ({
            ...prev,
            locals: {
              ...prev.locals,
              hadr: true,
              secondary_address_prefix: response.data.slice(0, -1),
              // NOTE: secondary_region should have been set during instance creation already, but some older
              // instances don't have it, so ensure we populate the secondary region here too
              secondary_region: azRegionPairs[customerData.locals.primary_region],
            },
            module: {
              ...prev.module,
              ...hadrjson,
            },
          }))
        })
      //...remove HADR components and release the VLAN reservation.
      } else {
        const modules = { ...customerData.module }
        const { soe_sec, hadr, data, ...noHadr } = modules
        setCustomerData((prev) => ({
          ...prev,
          locals: {
            ...prev.locals,
            hadr: false,
            secondary_address_prefix: ""
          },
          module: {
            ...noHadr,
          },
        }))
      }
    }
  }, [hadr])

  useEffect(() => {
    if (customerData) {
      customerData.locals.ssl_use_wildcard = !cert
      setCertModal(state => !state)
    }
  }, [cert])

  const handleSubmit = (evt) => {
    callHebridesApi(
      "PUT",
      `/v2/infra/modify/${selectedEnv}/${selectedCustomer}`,
      customerData
    ).then((response) => {
      setApiResponse(response)
    })
    evt.preventDefault()
  }

  //This side effect gets customers for a given environment
  useEffect(() => {
    if (selectedEnv != null) {
      callHebridesApi(
        "GET",
        `/v2/infra/modify/${selectedEnv}`,
        ""
      ).then((response) => setCustomerOptions(response.data))
    }
  }, [selectedEnv])

  //This effect grabs the customer JSON from storage
  useEffect(() => {
    setCert(false)
    if (selectedEnv != null && selectedCustomer !== "_blank") {
      callHebridesApi(
        "GET",
        `/v2/infra/modify/${selectedEnv}/${selectedCustomer}`
      ).then((response) => {
        setCustomerData(response.data)
      })
    }
  }, [selectedEnv, selectedCustomer])

  return (
    <>
      <Layout title="Infrastructure Changes">
        { hasSpecialAccess ? (
          <>
            { environments.state === 'ERROR' && <ErrorNotice /> }
            { environments.state === 'LOADING' && <StyledLinearProgress /> }
            { environments.state === 'OK' && (
              <>
                <div className="d-flex modify-select">
                  <div className="flex--item3">
                    <div className="form-group">
                      <label className="MuiTab-wrapper" htmlFor="environment">
                        Environment
                      </label>
                      <select
                        className="form-control"
                        id="environment"
                        style={{ borderColor: "#000" }}
                        onChange={(e) => handleEnvSelector(e)}
                      >
                        <option value="_blank"></option>
                        { Object.entries(environments.data).map(([value, label]) => <option key={value} value={value}>{label}</option>) }
                      </select>
                    </div>
                  </div>
                  <div className="flex--item3">
                    <div className="form-group">
                      <label className="MuiTab-wrapper" htmlFor="customer">
                        Customer
                      </label>
                      <select
                        className="form-control"
                        id="customer"
                        style={{ borderColor: "#000" }}
                        onChange={(e) => handleCustomerSelector(e)}
                      >
                        <option value="_blank"></option>
                        {customerOptions
                          ? customerOptions.map((x, y) => (
                              <option key={y}>
                                {x.customerName.toUpperCase()}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="flex--item3">
                    <div className="form-group">
                      <label className="MuiTab-wrapper" htmlFor="ssl_use_wildcard">
                        Private SSL
                      </label>
                      <select
                        className="form-control"
                        id="ssl_use_wildcard"
                        style={{ borderColor: "#000" }}
                        value={customerData ? (customerData.locals.ssl_use_wildcard ? 'No' : 'Yes') : 'No'}
                        disabled={customerData === null}
                        onChange={handleSSLSelector}
                      >
                        <option>No</option>
                        <option>Yes</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex--item3">
                    <div className="form-group">
                      <label className="MuiTab-wrapper" htmlFor="hadr">
                        HADR
                      </label>
                      <select
                        className="form-control"
                        id="hadr"
                        style={{ borderColor: "#000" }}
                        value={(customerData && customerData.locals.hadr) ? 'Yes' : 'No'}
                        disabled={(customerData === null || customerData.locals.hadr)}
                        onChange={handleHadrSelector}
                      >
                        <option>No</option>
                        <option>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <JsonForms
                  schema={configschema}
                  uischema={uischema}
                  data={customerData}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={({ errors, data }) => setCustomerData(data)}
                />
                <hr />
                <div className="d-flex">
                  <div className="flex--item3">
                    <button
                      className="s-btn s-btn__primary"
                      id="submit"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="flex--item6">
                    <div>
                      {apiResponse ? (
                        <ApiResponse response={apiResponse} />
                      ) : null}
                    </div>
                  </div>
                  { customerData ? (customerData.locals.ssl_use_wildcard ? null : (
                    <div className="flex--item3">
                      <CertModal builddata={customerData} onChange={handleModal} allowOverwrite={true} />
                    </div>
                  )) : null}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="d-flex">Invalid permissions.</div>
        )}
      </Layout>
      <Layout title="Upgrade Queue (Last 10)">
        <GetTaskQueue
          api={
            "/v2/infra/tasks/state?tasktype=runbookrun&runbook=InfraDeployment"
          }
        />
      </Layout>
    </>
  )
}
