import React, { useState, useEffect, useContext, useRef } from "react"

import { StyledLinearProgress } from "../StyledLoader"
import { LogItem } from "./TaskAccordion"
import { ModalContext } from "../../Contexts/ModalContext"
import { callHebridesApi } from "../../Helpers/fetch"

const TaskDetailBody = () => {
  const context = useContext(ModalContext)
  const [taskLogs, setTaskLogs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(0)

  useEffect(() => {
    console.log(context.showModal)
    if (context.showModal) {
      setLoading(true)
      const api = `/v2/infra/tasks/details/${encodeURIComponent(context.taskLogId)}`
      let isSubscribed = true
      callHebridesApi(
        "GET",
        api,
        ""
      )
        .then((response) => {
          setLoading(false)
          setTaskLogs(response.data)
        })
        .then(() => (isSubscribed ? (isSubscribed = false) : null))

      const getDetails = setInterval(() => {
        let isSubscribed = true
        callHebridesApi(
          "GET",
          api,
          ""
        )
          .then((response) => setTaskLogs(response.data))
          .then(() => (isSubscribed ? (isSubscribed = false) : null))
      }, 15000)
      return () => clearInterval(getDetails)
    }
    else
    {
      setTaskLogs(null)
      setClicked(0)
    }
  }, [context])

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index)
  }

  return (
    <>
      {loading ? (
        <StyledLinearProgress />
      ) : (
        <div className="accordion">
          {context.showModal && taskLogs
            ? taskLogs.map((k, index) => {
                return k.Name !== "Acquire packages" &&
                  k.Children.length > 0 ? (
                  <LogItem
                    onToggle={() => handleToggle(index)}
                    active={clicked === index}
                    key={index}
                    log={k}
                  />
                ) : null
              })
            : null}
        </div>
      )}
    </>
  )
}

export const TaskLogModal = () => {
  const ref = useRef()
  const context = useContext(ModalContext)

  useEffect(() => {
    const clickToClose = (e) => {
      if (context.showModal && ref.current && !ref.current.contains(e.target)) {
        context.handleViewLog()
      }
    }

    document.addEventListener("click", clickToClose)
    return () => {
      document.removeEventListener("click", clickToClose)
    }
  })

  return (
    <ModalContext.Consumer>
      {(context) => {
        if (context) {
          return (
            <aside
              className="s-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden={!context.showModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              data-controller="s-modal"
            >
              <div className="s-modal--dialog ws8 wmx8" ref={ref}>
                <h1 className="s-modal--header modal-header">
                  Task Log (Click step to expand)
                </h1>
                <div className="s-modal--body">
                  <TaskDetailBody />
                </div>
              </div>
            </aside>
          )
        }
        return null
      }}
    </ModalContext.Consumer>
  )
}
