import React, { useState, useEffect, useRef } from "react"

import { callHebridesApi } from "../Helpers/fetch"

export const CertModal = (props) => {
  const dialogRef = useRef()
  const [certDataBase64, setCertDataBase64] = useState('')
  const [certPassword, setCertPassword] = useState('')
  const [show, setShow] = useState(false)
  const [uploadStatusCode, setUploadStatusCode] = useState(null)
  const [uploadError, setUploadError] = useState(null)

  const canUpload = !!certDataBase64 // cert password is not required

  const uploadCert = async () => {
    if (!canUpload) {
      return
    }

    const environment = props.builddata.locals.environment
    const client = props.builddata.locals.client
    const putData = {
      existingCertDataSecretName: props.builddata.data?.azurerm_key_vault_secret?.['cert-data']?.name,
      existingCertPasswordSecretName: props.builddata.data?.azurerm_key_vault_secret?.['cert-pass']?.name,
      certDataBase64: certDataBase64,
      certPassword: certPassword
    }

    setUploadStatusCode(null)
    setUploadError(null)

    await callHebridesApi(
      "PUT",
      `/v2/soecert/${environment}/${client}${props.allowOverwrite ? '?allowOverwrite=true' : ''}`,
      putData
    )
      .then(result => {
        props.onChange(result.data)
        setUploadStatusCode(result.status)
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setUploadStatusCode(error.response.status)
          setUploadError(error.response.data || '400 Bad Request')
        } else {
          console.error(error)
          setUploadStatusCode(error.response ? error.response.status : 500)
          setUploadError("Something went wrong. Check the browser console for details.")
        }
      })
  }

  const readFileToBase64Async = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result.replace(/^data:.+;base64,/, ""))
      }

      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    const clickToClose = (e) => {
      if (show && dialogRef.current && !dialogRef.current.contains(e.target)) {
        setShow(false)
      }
    }

    document.addEventListener("click", clickToClose)
    return () => {
      document.removeEventListener("click", clickToClose)
    }
  })

  const uploadMessage = () => {
    return (
      <>
      { uploadStatusCode === 200 &&
        <div className="alert alert-success" role="alert">
          Certificate uploaded successfully.
        </div>
      }
      { uploadStatusCode !== null && uploadStatusCode !== 200 &&
        <div className="alert alert-danger" role="alert">
          {uploadError}
        </div>
      }
      </>
    );
  }

  const onFileChange = async ev => {
    const fileInput = ev.target
    if (fileInput.files.length == 1) {
      const certFile = fileInput.files[0]
      setCertDataBase64(await readFileToBase64Async(certFile))
    } else {
      setCertDataBase64('')
    }
  }

  return (
    <>
      <button
        type="button"
        className="s-btn s-btn__primary"
        onClick={() => setShow(true)}
      >
        Upload…
      </button>
      <aside
        className="s-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden={!show}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        data-controller="s-modal"
      >
        <div className="s-modal--dialog ws8 wmx8" ref={dialogRef}>
          <div className="s-modal--header modal-header">
            Client Provided Certificate and Password
          </div>
          <div className="s-modal--body">
            <label className="control-label" htmlFor="certFile">Select Certificate File</label>
            <div className="form-group">
              <input type="file" id="certFile" accept="application/x-pkcs12, .pfx, .p12" onChange={onFileChange} />
            </div>
            <label className="control-label" htmlFor="certPassword">SSL File Password</label>
            <div className="form-group">
              <input type="password" id="certPassword" className="sslcert" value={certPassword} onChange={e => setCertPassword(e.target.value)}/>
            </div>
            {uploadMessage()}
          </div>
          <div className="s-modal--footer">
            <button
              type="button"
              className="s-btn s-btn__primary"
              onClick={uploadCert}
              disabled={!canUpload}
            >
              Upload
            </button>
            <button
              type="button"
              className="s-btn s-btn__primary"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </div>
        </div>
      </aside>
    </>
  )
}
