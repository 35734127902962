import React, { useState, useEffect, useRef, useContext } from "react"
import { callHebridesApi } from "../../Helpers/fetch"
import { ModalContext } from "../../Contexts/ModalContext"
import { StyledLinearProgress } from "../StyledLoader"
import { makeStyles } from "@material-ui/core/styles"
import { ErrorNotice } from "../../Components/Common/ErrorNotice"
import { HebridesTable } from "../../Components/Common/HebridesTable"
import { StatusLabel } from "../../Components/Common/StatusLabel"

export const RecentTasksModal = () => {
    const ref = useRef()
    const context = useContext(ModalContext)

    useEffect(() => {
        const clickToClose = (e) => {
          if (
            context.isModalOpen && 
            ref.current && 
            !ref.current.contains(e.target) &&
            !(['svg', 'BUTTON', 'path'].includes(e.target.tagName))) {

            context.handleTaskModal()
          }
          console.log(e.target.tagName)
        }
    
        document.addEventListener("click", clickToClose)
        return () => {
          document.removeEventListener("click", clickToClose)
        }
    })

    return (
        <ModalContext.Consumer>
            {(context) => {return (<aside
              className="s-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden={!context.isModalOpen}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              data-controller="s-modal"
            >
              <div className="s-modal--dialog ws8 wmx8" ref={ref}>
                <h1 className="s-modal--header modal-header">
                  Recent Tasks
                </h1>
                <div className="s-modal--body">
                    <RecentTasksTable/>
                </div>
              </div>
            </aside>)}}
        </ModalContext.Consumer>
    )
}

const RecentTasksTable = () => {
    const context = useContext(ModalContext)
    const [isError, setIsError] = useState(false)
    const [recentTasks, setRecentTasks] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getRecentTasks = () => {
        if (context.isModalOpen) {
            setIsLoading(true)
            callHebridesApi(
                "GET",
                `/v2/infra/tenanttasks/${context.taskIds.tenantId}/${context.taskIds.envId}`,
                ""
            )
            .then((res) => {
                setRecentTasks(res.data.Items)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsError(true)
            })
        }
    }

    useEffect(() => {
        getRecentTasks()
    }, [context])

    const headerStyles = makeStyles((theme) => ({
      root: {
        width: "100%",
      },
      paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }))

    const columns = [
      { id: "task", label: "Task" },
      { id: "state", label: "State" },
      { id: "starttime", label: "Start Time" },
      { id: "completedtime", label: "Completed Time" },
      { id: "duration", label: "Duration" },
    ]

    const classes = headerStyles()
    const footerStyles = makeStyles((theme) => ({
      root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
    }))

    const headerProps = {
      classes,
      columns,
      isSorted: false
    }

    const paginationProps = {
      rowsPerPageOptions: [
        5,
        10,
        15,
        {label: "All", value: recentTasks?.length}
      ],
      colSpan: 5,
      count: recentTasks?.length, 
      footerStyle: footerStyles()
    }

    const tableProps = {
      Row: TaskRow,
      items: recentTasks,
      headerProps,
      isPaginated: true,
      initialRowCount: 5,
      paginationProps
    }

    return isLoading ? (
            <StyledLinearProgress />
        ) : (
          <>
          {isError && <ErrorNotice />}
          {recentTasks && !isError && <HebridesTable {...tableProps} />}
          </>
        )
}

function TaskRow (props) {
    const { row } = props;
    const startTime = row.StartTime ? new Date(row.StartTime).toUTCString() : null
    const completedTime = (row.IsCompleted && row.CompletedTime) ? new Date(row.CompletedTime).toUTCString() : null

    return (
        <>
            <tr style={{ borderBottom: "unset" }}>
                <th scope="row">{row.Description}</th>
                <td>{row.State && <StatusLabel taskState={row.State}/>}</td>
                <td>{startTime}</td>
                <td>{completedTime}</td>
                <td>{row.Duration}</td>
            </tr>
        </>
    )
}
