// A dictionary of Azure region pairs compatible for SOE HADR
export const azRegionPairs = {
  "West US": "East US",
  "East US": "West US",
  "West US 2": "West Central US",
  "West Central US": "West US 2",
  "North Central US": "South Central US",
  "South Central US": "North Central US",
  "Canada Central": "Canada East",
  "Canada East": "Canada Central",
  "North Europe": "West Europe",
  "West Europe": "North Europe",
  "UK South": "UK West",
  "UK West": "UK South",
  "Australia East": "Australia Southeast",
  "Australia Southeast": "Australia East",
  "": "",
}
