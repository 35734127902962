import React from "react"

export const ApiResponse = (props) => {
  return (
    <div id="action-result">
      {props.response.status === 200 ? (
        <div className="alert alert-success" role="alert">
          Success!
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          <div>
            <b>UH-OH! SOMETHING WENT WRONG.</b>
          </div>
          <div>
            <div align="left">Error Details:</div>
            <div align="left">{props.response.data}</div>
          </div>
        </div>
      )}
    </div>
  )
}
