export const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/client",
        },
        {
          type: "Control",
          scope: "#/properties/locals/properties/environment",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/ticker",
        },
        {
          type: "Control",
          scope: "#/properties/locals/properties/dns_endpoint",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/primary_region",
        },
        {
          type: "Control",
          scope: "#/properties/locals/properties/primary_address_prefix",
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/secondary_region",
        },
        {
          type: "Control",
          scope: "#/properties/locals/properties/secondary_address_prefix",
        },
      ],
      rule: {
        effect: "SHOW",
        condition: {
          scope: "#/properties/locals/properties/hadr",
          schema: {
            const: true,
          },
        },
      },
    },
  ],
}
