export async function callMsGraph(accessToken, endpoint) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append("Authorization", bearer)

  const options = {
    method: "GET",
    headers: headers,
  }

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error))
}
