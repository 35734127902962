import React from "react"
import { AuthenticatedTemplate } from "@azure/msal-react"
import { UserWidget } from "./UserWidget"
import { ErrorBoundary } from "../ErrorBoundary"

export const Header = () => {
  return (
    <>
      <header className="app-header">
        <div className="app-header__logo">
          <div className="logo-src"></div>
        </div>
        <div className="app-header__content">
          <div className="app-header-right">
            <div className="widget-content">
              <AuthenticatedTemplate>
                <ErrorBoundary>
                  <UserWidget />
                </ErrorBoundary>
              </AuthenticatedTemplate>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
