import { callHebridesApi } from "../../Helpers/fetch"

const GetCustomerInstances = async () => {
  return callHebridesApi("GET", "/v2/decomm-metadata", "")
    .then(response => {
      return response.data.instances;
    });
}

export const GetCustomersByEnv = (env) => {
  return GetCustomerInstances().then(instances => {
    const envInstances = instances.filter(instance => instance.environment.toLowerCase() == env.toLowerCase());

    return callHebridesApi("GET", `/GetAzRSG/${env}`, "").then(response => {
      const customerConfigs = []
      let envResourceGroups = response.data;
      envResourceGroups
        .sort((a, b) => {
          return a.tags.client
            .toUpperCase()
            .localeCompare(b.tags.client.toUpperCase())
        })
        .forEach(resourceGroup => {
          let instance = envInstances.find(
            instance => instance.name.toLowerCase() === resourceGroup.tags.client
          )

          if (instance) {
            var hadr = resourceGroup.tags.hadr
              ? ` - (HADR: ${resourceGroup.tags.hadr.toUpperCase()})`
              : ""

            customerConfigs.push(
              `${instance.name.toUpperCase()} - (${resourceGroup.name})${hadr}`
            )
          }
        })
      return customerConfigs
    })
  });
}
