import * as React from 'react'

interface IStatusLabel {
    taskState: string
    text: string
}

export const StatusLabel = ({ taskState, text } : IStatusLabel) => {
    if (text == undefined || text === "") {
        text = taskState
    }
    if (taskState == undefined || taskState === "") {
        taskState = "unknown"
    }
    const icons: Record<string, object> = {
        canceled: <span className="s-badge bg-black-50 bc-black-600 fc-black-800" title="Canceled">{text}</span>,
        cancelling: <span className="s-badge bg-yellow-100 bc-yellow-600 fc-yellow-900" title="Cancelling">{text}</span>,
        executing: <span className="s-badge bg-yellow-100 bc-yellow-600 fc-yellow-900" title="Executing">{text}</span>,
        failed: <span className="s-badge bg-red-100 bc-red-600 fc-red-900" title="Failed">{text}</span>,
        queued: <span className="s-badge bg-blue-100 bc-blue-600 fc-blue-900" title="Queued">{text}</span>,
        success: <span className="s-badge bg-green-100 bc-green-600 fc-green-900" title="Success">{text}</span>,
        timedout: <span className="s-badge bg-orange-100 bc-orange-600 fc-orange-900" title="Timed out">{text}</span>,
        unknown: <span className="s-badge bc-black-600" title="Unknown">{text}</span>
    }

    return (icons[taskState?.toLowerCase()])
}

export default StatusLabel;