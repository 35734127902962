import React, { useRef } from "react"

export const LogItem = ({ log, active, onToggle }) => {
  const ref = useRef()

  return (
    <>
      <div className={`s-card ${active ? "active" : ""}`}>
        <button
          type="button"
          className="s-btn s-btn__primary task-modal-btn"
          onClick={onToggle}
        >
          {log.Name}
          <span className="control"></span>
        </button>
        <div
          ref={ref}
          className="log_wrapper"
          style={
            active && ref.current !== undefined
              ? { height: ref.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className="card-body">
            <div className="list-group">
              {log.Children[0].LogElements.filter(
                (c) => c.Category === "Info"
              ).map((m, i) => {
                return (
                  <div className="list-group-item" key={i}>
                    <pre>{m.MessageText}</pre>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
