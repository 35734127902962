import React, { useState, useEffect } from "react"
import { ErrorBoundary } from "../../Components/ErrorBoundary"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { StyledLinearProgress } from "../../Components/StyledLoader"
import { callHebridesApi } from "../../Helpers/fetch"
import { RecentTasksModal } from "../../Components/Tasks/RecentTasksModal"
import { ModalContext } from "../../Contexts/ModalContext"
import { HebridesTable } from "../../Components/Common/HebridesTable"
import { StatusLabel } from "../../Components/Common/StatusLabel"

const columns = [
  {
    id: "client",
    label: "Client Name",
    style: {
      width: "20%",
      padding: 15,
      background: "rgba(255,255,255,0.45)",
      borderBottom: "1px solid grey",
    },
  },
  { id: "ticker", label: "Ticker" },
  { id: "rsg", label: "Resource Group" },
  { id: "environment", label: "Environment" },
  { id: "soeversion", label: "SOE Version" },
  { id: "fqdn", label: "DNS Endpoint" },
  { id: "azregion", label: "Azure Region" },
  { id: "teams", label: "Private Teams" },
  { id: "hadr", label: "HADR" },
  { id: "aks", label: "AKS" },
  { id: "cloudflare", label: "Cloudflare" },
  { id: "lateststatus", label: "Status"},
  {id: "recenttasks", label: "Recent Tasks"},
]

const headerStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  }
}))

const footerStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

export const Dashboard = () => {
  const classes = headerStyles();
  const [loadState, setLoadState] = useState({
    loading: true,
    status: null,
  })
  const [dashdata, setData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [taskIds, setTaskIds] = useState({})

  useEffect(() => {
    callHebridesApi("GET", "/v2/infra/dashboard", "").then((response) => {
      setData(response.data)
      setLoadState((prev) => ({
        ...prev,
        loading: false,
        status: response.status,
      }))
    })
  }, [])

  const handleTaskModal = () => {
    setIsModalOpen((state) => !state)
  }

  const handleButtonClick = (tenantId, envId) => {
    handleTaskModal()
    setTaskIds({tenantId, envId})
  }
  const data = dashdata != null ? dashdata : null

  function Row(props) {
    const { row } = props

    return (
      <>
        <tr style={{ borderBottom: "unset" }}>
          <td>{row.clientname}</td>
          <td>{row.ticker}</td>
          <td>{row.rsg}</td>
          <td>{row.environment}</td>
          <td>{row.soeversion}</td>
          <td>
            <a href={row.clientfqdn}>{row.clientfqdn}</a>
          </td>
          <td>{row.location.toUpperCase()}</td>
          <td>{row.teams}</td>
          <td>{row.hadr && row.hadr.toUpperCase()}</td>
          <td>{row.aks}</td>
          <td>{row.cloudflare}</td>
          <td className="ta-center">
            {row.state && <StatusLabel taskState={row.state} />}
          </td>
          <td className="ta-center">      
            <button
              type="button"
              className="s-btn s-btn__link"
              onClick={() => handleButtonClick(row.tenantId, row.environmentId)}>
              View Tasks
            </button>
          </td>
        </tr>
      </>
    )
  }

  const headerProps = {
    classes,
    columns,
    isSorted: true
  }

  const paginationProps = {
    rowsPerPageOptions: [
      20,
      50,
      100,
      { label: "All", value: data?.length },
    ],
    colSpan: 13,
    count: data?.length,
    footerStyle: footerStyles()
  }

  const tableProps = data ? {
    Row,
    items: data,
    headerProps,
    isPaginated: true,
    initialRowCount: 20,
    paginationProps
  } : null

  return loadState.loading ? (
    <StyledLinearProgress />
  ) : (
    <ErrorBoundary>
      <div className="dashboard-title-heading">
        Hosted Instances
      </div>
      {data && <HebridesTable {...tableProps} />}
      {isModalOpen && (
        <ModalContext.Provider value={{ isModalOpen, taskIds, handleTaskModal }}>
          <RecentTasksModal/>
        </ModalContext.Provider>
      )}
    </ErrorBoundary>
  )
}
