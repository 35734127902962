import React, { createContext, useState, useEffect } from "react"
import { useMsal } from "@azure/msal-react"
import { callMsGraph } from "../Components/Auth/Graph"
import { protectedResources } from "../Components/Auth/AuthConfig"

//ToDo Combine into a single context for cleaner code throughout
export const UserContext = createContext()
export const UserMemberOfContext = createContext()
export const AppContext = createContext()

export const UserContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal()
  const [data, setGraphData] = useState(null)

  useEffect(() => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        scopes: protectedResources.graphMe.scopes,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(
          response.accessToken,
          protectedResources.graphMe.endpoint
        ).then((response) => setGraphData(response))
      })
      .catch((e) => {
        instance.acquireTokenRedirect({
          scopes: protectedResources.graphMe.scopes,
          account: accounts[0],
        })
      })
  }, [accounts, instance])

  return (
    <UserContext.Provider value={{ data }}>{children}</UserContext.Provider>
  )
}
UserContextProvider.displayName = "MSGraphMeContext"

export const UserMemberOfContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal()
  const [data, setGraphMemberOfData] = useState(null)

  useEffect(() => {
    instance
      .acquireTokenSilent({
        scopes: protectedResources.graphMe.scopes,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(
          response.accessToken,
          protectedResources.graphMe.memberOfendpoint
        ).then((response) => setGraphMemberOfData(response))
      })
      .catch((e) => {
        instance.acquireTokenRedirect({
          scopes: protectedResources.graphMe.scopes,
          account: accounts[0],
        })
      })
  }, [accounts, instance])

  return (
    <UserMemberOfContext.Provider value={{ data }}>
      {children}
    </UserMemberOfContext.Provider>
  )
}
UserMemberOfContext.displayName = "MSGraphMemberOfContext"

export const AppContextProvider = ({ children }) => {
  const { instance, accounts } = useMsal()
  const [roleassignments, setRoleAssignments] = useState(null)

  useEffect(() => {
    instance
      .acquireTokenSilent({
        scopes: protectedResources.graphMe.scopes,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(
          response.accessToken,
          protectedResources.graphMe.roleAssignmentsendpoint
        ).then((response) => setRoleAssignments(response))
      })
      .catch((e) => {
        instance.acquireTokenRedirect({
          scopes: protectedResources.graphMe.scopes,
          account: accounts[0],
        })
      })
  }, [accounts, instance])

  return (
    <AppContext.Provider value={{ roleassignments }}>
      {children}
    </AppContext.Provider>
  )
}
AppContextProvider.displayName = "MSGraphAppRoleContext"
