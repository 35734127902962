import { createAjv } from "@jsonforms/core";
import ipaddr from "ipaddr.js";

// AjV is used under the hood for JSON Forms
const ajv = createAjv();

// This file extends the default AjV instance with custom formats and keywords but also returns the standalone validation functions for use in the React UI more easily.

export const validateNoCidrOverlaps = (cidrArray) => {
  const errorSet = new Set();
  const parsedEntries = cidrArray.map((cidr) => {
    try {
      const ip = ipaddr.parse(cidr);
      return { ip, version: ip.kind(), prefixSize: null, raw: cidr };
    } catch (e) {
      try {
        const [ip, prefixSize] = ipaddr.parseCIDR(cidr);
        return { ip, version: ip.kind(), prefixSize, raw: cidr };
      } catch (e) {
        return null;
      }
    }
  });

  for (let i = 0; i < parsedEntries.length; i++) {
    for (let j = i + 1; j < parsedEntries.length; j++) {
      const a = parsedEntries[i];
      const b = parsedEntries[j];

      if (!a || !b) continue;
      if (a.version !== b.version) continue;

      if (
        a.prefixSize === null &&
        b.prefixSize === null &&
        a.ip.toString() === b.ip.toString()
      ) {
        errorSet.add(`Duplicate IP: ${a.raw}`);
      }

      if (a.prefixSize !== null && b.prefixSize !== null) {
        if (a.ip.match(b.ip, Math.min(a.prefixSize, b.prefixSize))) {
          errorSet.add(`CIDR block ${a.raw} overlaps with ${b.raw}.`);
        }
      }

      if (a.prefixSize !== null && b.prefixSize === null && a.ip.match(b.ip, a.prefixSize)) {
        errorSet.add(`IP ${b.raw} falls within CIDR range ${a.raw}.`);
      }

      if (b.prefixSize !== null && a.prefixSize === null && b.ip.match(a.ip, b.prefixSize)) {
        errorSet.add(`IP ${a.raw} falls within CIDR range ${b.raw}.`);
      }
    }
  }

  return [...errorSet];
};

ajv.addFormat("ipOrCIDR", {
  validate: (data) => {
    return ipaddr.isValid(data) || ipaddr.isValidCIDR(data)
  },
});

ajv.addKeyword("noCidrOverlaps", {
  type: "array",
  validate: (schema, cidrArray) => {
    const errors = validateNoCidrOverlaps(cidrArray);
    return errors.length === 0;
  },
  errors: true,
});

export default ajv;