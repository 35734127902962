import { useState, useEffect } from "react"
import { callHebridesApi } from "./fetch"

export type BuildMetadata = {
    terraformStateStorageAccountName: string | null
}

export type BuildMetadataResult =
  | { state: 'LOADING' }
  | { state: 'OK', data: BuildMetadata }
  | { state: 'ERROR', error: Error };

/**
 * A React hook that fetches metadata required for creating new SOE instances.
 */
export function useBuildMetadata(): BuildMetadataResult {
  const [result, setResult] = useState<BuildMetadataResult>({ state: 'LOADING' })

  useEffect(() => {
    callHebridesApi("GET", "/v2/build-metadata", "")
      .then(response => setResult({ state: 'OK', data: response.data }))
      .catch(error => setResult({ state: 'ERROR', error: error }))
  }, []);

  return result;
}
