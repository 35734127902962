import React, { useContext } from "react"
import { useMsal } from "@azure/msal-react"
import { UserContext } from "../../Contexts/UserContext"

export const UserWidget = () => {
  const msal = useMsal()
  const context = useContext(UserContext)
  if (!context.data) {
    return null
  }

  function doLogout() {
    // Passing in the active account is optional but lets us avoid a server-side account picker
    // The app registration must have the optional "login_hint" token claim (https://stackoverflow.com/a/71299790)
    msal.instance.logoutRedirect({ account: msal.instance.getActiveAccount() })
  }

  return (
    <div className="d-flex ai-center gs16">
      <div className="flex--item">
        {context.data.displayName &&
          <div className="widget-heading">{context.data.displayName}</div>
        }
        {context.data.jobTitle &&
          <div className="widget-subheading">{context.data.jobTitle}</div>
        }
      </div>
      <div className="flex--item">
        <button className="s-btn s-btn__xs s-btn__muted s-btn__filled" onClick={doLogout}>Sign out</button>
      </div>
    </div>
  )
}
