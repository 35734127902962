import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { UserMemberOfContext } from "../../Contexts/UserContext"
import staticConfig from '../../staticConfig.json'

const NavMenuLink = props => (
  <li>
    <Link to={props.route}>
      <i className={`fas ${props.iconClass}`}></i>
      { props.label }
    </Link>
  </li>
);

const NavMenu = () => {
  const userMemberOfContext = useContext(UserMemberOfContext)
  const hasMembershipData = !!userMemberOfContext.data
  if (!hasMembershipData) {
    return null
  }

  // userMemberOfContext is an Axios response for a call to this endpoint: https://learn.microsoft.com/en-us/graph/api/user-list-memberof?view=graph-rest-1.0&tabs=http
  // userMemberOfContext.data.value is therefore an array of these objects: https://learn.microsoft.com/en-us/graph/api/resources/directoryobject?view=graph-rest-1.0
  const hasSpecialAccess = userMemberOfContext.data.value.some(
    directoryObject => (staticConfig.UserAuth.SpecialAccessGroups || []).includes(directoryObject.id)
  );

  return (
    <div className="s-sidebarwidget">
      <ul className="vertical-nav-menu">
        <NavMenuLink route="/" iconClass="fa-home" label="Dashboard" />
        <NavMenuLink route="/build" iconClass="fa-cogs" label="Create SOE Instance" />
        { hasSpecialAccess && <NavMenuLink route="/modify" iconClass="fa-edit" label="Infrastructure Changes" /> }
        <NavMenuLink route="/upgrade" iconClass="fa-calendar" label="Schedule SOE Upgrade" />
        <NavMenuLink route="/decomm" iconClass="fa-trash" label="Decommission" />
      </ul>
    </div>
  )
}

export default NavMenu
