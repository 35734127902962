import React from "react"
import { Route, Switch } from "react-router-dom"

//Import Pages
import { Dashboard } from "./Dashboard"
import { Build } from "../Build/Build"
import { Upgrade } from "../Upgrade/Upgrade"
import { Modify } from "../Modify/Modify"
import { Decomm } from "../Decomm/Decomm"

export const Pages = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/build" component={Build} />
      <Route exact path="/modify" component={Modify} />
      <Route exact path="/upgrade" component={Upgrade} />
      <Route exact path="/decomm" component={Decomm} />
    </Switch>
  )
}
