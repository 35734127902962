import { LogLevel } from "@azure/msal-browser"
import staticConfig from "../../staticConfig.json"

export const msalConfig = {
  auth: {
    clientId: staticConfig.UserAuth.ClientId, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${staticConfig.UserAuth.TenantId}`,
    redirectUri: window.location.origin + "/aad-callback", // Defaults to "https://login.microsoftonline.com/common"
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

// Dion: I don't understand what this scope is necessary for, but token signature validation on the backend fails if
// we don't include this when sending requests to the backend (even though the backend doesn't check for this scope
// specifically?). Just passing the usual "openid" or "user.read" scopes here isn't enough either.
// You can see the scopes on the "SOEDeploymentApp" app registration on the "Stack Exchange, Inc." tenant here:
// https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/CallAnAPI/appId/89ba2116-765b-49a3-93cc-ee140ea5b185/isMSAApp~/false
// https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/ProtectAnAPI/appId/89ba2116-765b-49a3-93cc-ee140ea5b185/isMSAApp~/false
export const loginRequest = {
  scopes: [
    "https://stackoverflow.onmicrosoft.com/soedeployment/access_as_user",
  ],
}

export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    memberOfendpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
    roleAssignmentsendpoint: "https://graph.microsoft.com/v1.0/me/appRoleAssignments",
    scopes: ["User.Read"],
  },
}
