import { withStyles } from "@material-ui/styles"
import { LinearProgress } from "@material-ui/core"

export const StyledLinearProgress = withStyles({
  root: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colorPrimary: {
    backgroundColor: "#fafbfc",
  },
  barColorPrimary: {
    backgroundColor: "#F48024",
  },
})(LinearProgress)
