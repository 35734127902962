import React, { createContext, useState, useContext } from "react"

const SidebarContext = createContext()

const SidebarContextProvider = ({ children }) => {
  const [isOpened, toggleIsOpened] = useState(false)

  return (
    <SidebarContext.Provider value={{ isOpened, toggleIsOpened }}>
      {children}
    </SidebarContext.Provider>
  )
}

const useSidebarContext = () => {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error(
      "useSidebarContext must be used within a SidebarContextProvider"
    )
  }
  return context
}

export { SidebarContextProvider, useSidebarContext }
