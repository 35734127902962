import { useState, useEffect } from "react"
import { callHebridesApi } from "./fetch"

export type DeploymentEnvironments = { [key: string]: string }

export type DeploymentEnvironmentsResult =
  | { state: 'LOADING' }
  | { state: 'OK', data: DeploymentEnvironments }
  | { state: 'ERROR', error: Error };

/**
 * A React hook that fetches a dictionary of available deployment environments.
 */
export function useDeploymentEnvironments(): DeploymentEnvironmentsResult {
  const [result, setResult] = useState<DeploymentEnvironmentsResult>({ state: 'LOADING' })

  useEffect(() => {
    callHebridesApi("GET", "/v2/environments", "")
      .then(response => setResult({ state: 'OK', data: response.data }))
      .catch(error => setResult({ state: 'ERROR', error: error }))
  }, []);

  return result;
}
