import React from "react"

export const Layout = (props) => {
  return (
    <>
      <div className="d-grid panel panel-primary">
        <div className="d-grid grid--col-all panel-heading">{props.title}</div>
      </div>
      <div className="d-grid panel panel-primary">
        <div className="d-grid grid--col-all panel-body">{props.children}</div>
      </div>
    </>
  )
}
