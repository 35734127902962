import React, { useState, useEffect } from "react"
import { callHebridesApi } from "../../Helpers/fetch"
import { ErrorBoundary } from "../../Components/ErrorBoundary"
import { ErrorNotice } from "../../Components/Common/ErrorNotice"
import { ApiResponse } from "../../Components/APIResponse"
import { Layout } from "../../Components/PageLayout"
import { StyledLinearProgress } from "../../Components/StyledLoader"
import { TaskQueue, dispatchTaskQueueRefresh } from "../../Components/Tasks/TaskQueue"
import { CertModal } from "../../Components/CertModal"
import { JsonForms } from "@jsonforms/react"
import customAjv from "../../Helpers/customValidations"
import { materialRenderers } from "@jsonforms/material-renderers"
import { useBuildMetadata } from "../../Helpers/useBuildMetadata"
import { useDeploymentEnvironments } from "../../Helpers/useDeploymentEnvironments"
import { azRegionPairs } from "../../Helpers/azRegionPairs"

import {
  configschema,
  configjson,
  hadrjson,
} from "../../Components/ConfigSchema/configschema"
import { uischema } from "./UiSchema/Schema"
import isValidHostname from "is-valid-hostname"

/**
 * Returns a copy of the given 'configschema' with the available SOE instance environments filtered to the dictionary
 * given in 'environments'. It's expected that 'environments' is an object where the keys and values can map directly
 * to the JSON schema oneOf 'const' and 'title' properties.
 */
function filterConfigSchema(configschema, environments) {
  let filteredSchema = { ...configschema }
  filteredSchema.properties.locals.properties.environment.oneOf = Object.entries(environments)
    .map(([value, label]) => ({ const: value, title: label }))
  return filteredSchema;
}

export const Build = () => {
  const [buttons, setButtons] = useState({
    modal: false,
    submitDisabled: true,
  })
  const [vlanOffset, setVlanOffset] = useState(0)
  const [buildData, setBuildData] = useState(configjson)
  const [submissionResult, setSubmissionResult] = useState(null);
  const [fqdnError, setFQDNError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form isn't ready until we grab the build metadata required for creating new instances, the dictionary of valid
  // environments, and filter the config schema to only include those envs
  const buildMetadata = useBuildMetadata()
  const environments = useDeploymentEnvironments()
  const filteredConfigSchema = environments.state === 'OK'
    ? filterConfigSchema(configschema, environments.data)
    : null;

  let readyState
  if (buildMetadata.state == 'ERROR' || environments.state == 'ERROR') {
    readyState = 'ERROR'
  } else if (buildMetadata.state == 'LOADING' || environments.state == 'LOADING') {
    readyState = 'LOADING'
  } else {
    readyState = 'OK'
  }

  const doSubmit = ({ force }) => {
    var fomattedRequestData = {
      ...buildData,
      locals: {
        ...buildData["locals"],
        dns_endpoint: buildData.locals.dns_endpoint.toLowerCase()
      }
    }

    setIsSubmitting(true);
    setSubmissionResult(null);

    callHebridesApi(
      "POST",
      `/v2/build/${fomattedRequestData.locals.client}/${fomattedRequestData.locals.environment}${force ? '?allowOverwrite=true' : ''}`,
      fomattedRequestData
    )
      .then(response => {
        setSubmissionResult({ state: 'OK', response: response })
        dispatchTaskQueueRefresh()
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 409) {
            setSubmissionResult({ state: 'INSTANCE_CONFLICT', message: error.response.data })
          } else {
            setSubmissionResult({ state: 'ERROR', response: error.response })
          }
        } else {
          console.error(error)
          setSubmissionResult({
            state: 'ERROR',
            response: { status: 500, data: 'Check the browser console for more details.' }
          })
        }
      })
      .then(() => setVlanOffset(vlanOffset + 1))
      .finally(() => setIsSubmitting(false))
  }

  const handleSliders = (event) => {
    let locals

    event.target.id === "ssl_use_wildcard"
      ? (locals = {
          locals: {
            ...buildData["locals"],
            [event.target.id]: !buildData["locals"][event.target.id],
            ssl_cert_data: "${data.azurerm_key_vault_secret.cert-data.value}",
            ssl_cert_password:
              "${data.azurerm_key_vault_secret.cert-pass.value}",
          },
        })
      : (locals = {
          locals: {
            ...buildData["locals"],
            [event.target.id]: !buildData["locals"][event.target.id],
          },
        })
    setBuildData(() => {
      return {
        ...buildData,
        ...locals,
      }
    })

    if (event.target.id === "hadr") {
      if (event.target.checked) {
        callHebridesApi(
          "GET",
          `/v2/infra/vlans?operation=getnextavailable&subscription=${
            buildData.locals.environment
          }&offset=${vlanOffset + 1}`,
          ""
        ).then((response) => {
          setBuildData((prev) => ({
            ...prev,
            locals: {
              ...prev.locals,
              secondary_address_prefix: response.data.slice(0, -1),
            },
            module: {
              ...prev.module,
              ...hadrjson,
            },
          }))
        })
      } else {
        const modules = { ...buildData.module }
        const { soe_sec, hadr, ...noHadr } = modules
        setBuildData((prev) => ({
          ...prev,
          locals: {
            ...prev.locals,
            secondary_address_prefix: "",
          },
          module: {
            ...noHadr,
          },
        }))
      }
    }
  }

  const handleModal = (newData) => {
    setBuildData((prev) => ({
      ...prev,
      ...newData,
    }))
  }

  //Side Effect to enable submit button when req fields are filled.
  useEffect(() => {
    if (
      buildData.locals.client &&
      buildData.locals.environment &&
      buildData.locals.dns_endpoint &&
      buildData.locals.ticker &&
      buildData.locals.primary_region &&
      buildData.locals.primary_address_prefix
    ) {
      setButtons(prev => ({
        ...prev,
        submitDisabled: false,
      }));
    }
  }, [buildData])

  // Effect to update various fields and grab next VLAN subnet when build metadata, environment or ticker changes
  useEffect(() => {
    if (buildMetadata.state !== 'OK') {
      return
    }

    const storageaccount = buildMetadata.data.terraformStateStorageAccountName
    let fqdn = buildData.locals.dns_endpoint

    if (buildData.locals.environment === "dev") {
      if (buildData.locals.ticker) {
        if (!buildData.locals.dns_endpoint || buildData.locals.dns_endpoint.includes("stackenterprise.co")) {
          fqdn = `${buildData.locals.ticker}.dev.stackenterprise.co`
        }
      }
    } else if (buildData.locals.ticker) {
      if (!buildData.locals.dns_endpoint || buildData.locals.dns_endpoint.includes("stackenterprise.co")) {
        fqdn = `${buildData.locals.ticker}.stackenterprise.co`
      }
    }

    callHebridesApi(
      "GET",
      `/v2/infra/vlans?operation=getnextavailable&offset=${vlanOffset}`,
      ""
    ).then(response => {
      setBuildData((prev) => ({
        ...prev,
        locals: {
          ...prev.locals,
          dns_endpoint: `${fqdn}`,
          primary_address_prefix: response.data.slice(0, -1),
        },
        terraform: {
          backend: {
            azurerm: {
              ...prev.terraform.backend.azurerm,
              storage_account_name: storageaccount,
            },
          },
        },
      }))
    })
  }, [buildMetadata, buildData.locals.environment, buildData.locals.ticker])

  //Validate FQDN
  useEffect(() => {
    if (!validateFQDN(buildData.locals.dns_endpoint)) {
      setButtons((prev) => ({
        ...prev,
        submitDisabled: true,
      }));
    } else {
      setFQDNError(null)
    } 
  }, [buildData])

  // Validate Client Name (TEAMS-8557)
  useEffect(() => {
    // Client names can consist of alphanumerics, space, and hyphen, but must start and end with only alphanumerics
    const validClientNameStart = /^[0-9A-Za-z]/
    const validClientNameEnd = /[0-9A-Za-z]$/
    const validClientName = /^[0-9A-Za-z\x20\-]+$/
    const isValid =
      validClientNameStart.test(buildData.locals.client) &&
      validClientNameEnd.test(buildData.locals.client) &&
      validClientName.test(buildData.locals.client)
    if (!isValid) {
      setButtons((prev) => ({
        ...prev,
        submitDisabled: true,
      }))
    }
  }, [buildData])

  // Validate SSL cert (TEAMS-10261)
  // Ensure we're either using the wildcard cert, or the user has uploaded a custom cert
  useEffect(() => {
    const hasUploadedCustomCert = (
      buildData.data &&
      buildData.data.azurerm_key_vault_secret &&
      buildData.data.azurerm_key_vault_secret["cert-data"] &&
      buildData.data.azurerm_key_vault_secret["cert-data"].name
    );
    const hasValidCert = (buildData.locals.ssl_use_wildcard || hasUploadedCustomCert);

    if (!hasValidCert) {
      setButtons((prev) => ({
        ...prev,
        submitDisabled: true,
      }))
    }
  }, [buildData])

  // Set secondary region pair for HADR
  useEffect(() => {
    setBuildData((prev) => ({
      ...prev,
      locals: {
        ...prev.locals,
        secondary_region: azRegionPairs[buildData.locals.primary_region],
      },
    }))
  }, [buildData.locals.primary_region])

  useEffect(() => {
    var enableCloudflareRoutingInputId = "enable_cloudflare_routing";
    var enableCloudflareRoutingInput = document.getElementById(enableCloudflareRoutingInputId);

    if(enableCloudflareRoutingInput != null) {
      if(enableCloudflareRoutingInput.checked) {
        buildData.locals.enable_cloudflare = true;
      }
    }
  }, [buildData.locals.enable_cloudflare_routing])

  function validateFQDN(fqdn) {
    const FQDNRegex = new RegExp(
      /^(http|https)|((stackoverflow))\.com|[^a-zA-Z0-9\.\-\_]/
    )

    if (!fqdn) {
      setFQDNError("FQDN cannot be empty")
      return false
    } else if (FQDNRegex.test(fqdn)) {
      setFQDNError("FQDN cannot contain http, https, or stackoverflow")
      return false
    } else if (!isValidHostname(fqdn)) {
      setFQDNError("Invalid FQDN hostname")
      return false
    }  else {
      setFQDNError(null)
      return true
    }
  }

  return (
    <>
      <Layout title="Build a New SOE Customer">
      { readyState === 'ERROR' && <ErrorNotice /> }
      { readyState === 'LOADING' && <StyledLinearProgress /> }
      { readyState === 'OK' && (
        <>
          <JsonForms
            schema={filteredConfigSchema}
            uischema={uischema}
            data={buildData}
            renderers={materialRenderers}
            validationMode={"ValidateAndHide"}
            ajv={customAjv}
            onChange={({ errors, data }) => setBuildData(data)}
          />
          <div className="d-flex">
            <div className="flex--item3">
              <label className="control-label" htmlFor="hadr">
                High Availability (HADR)
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={buildData.locals.hadr}
                    name="enableHadr"
                    id="hadr"
                    onChange={(e) => {
                      handleSliders(e)
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
            </div>
            <div className="flex--item3">
              <label className="control-label" htmlFor="teams">
                Enable Teams
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={buildData.locals.teams}
                    name="teams"
                    id="teams"
                    onChange={(e) => handleSliders(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
            </div>
            <div className="flex--item3">
              <label
                className={
                  buildData.locals.client && buildData.locals.environment
                    ? "control-label"
                    : "control-label-disabled"
                }
                htmlFor="ssl_use_wildcard"
              >
                Private SSL Certificate
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name="customCert"
                    id="ssl_use_wildcard"
                    disabled={
                      !buildData.locals.client || !buildData.locals.environment
                    }
                    onChange={(e) => {
                      setButtons((prev) => ({
                        ...prev,
                        modal: !prev.modal,
                      }))
                      handleSliders(e)
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
              <fieldset className="form-group">
                {buttons.modal ? (
                  <CertModal builddata={buildData} onChange={handleModal} />
                ) : null}
              </fieldset>
            </div>
            <div className="flex--item3">
              <label className="control-label" htmlFor="sendgrid">
                Enable SendGrid
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    name="enableSendGrid"
                    id="sendgrid"
                    checked={buildData.locals.sendgrid}
                    onChange={(e) => handleSliders(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
            </div>
          </div>
          <div className="d-flex">
            <div className="flex--item3">
              <label className="control-label" htmlFor="cloudflare">
                (Preview) Enable Cloudflare
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={buildData.locals.enable_cloudflare}
                    name="enableCloudflare"
                    disabled={buildData.locals.enable_cloudflare_routing}
                    id="enable_cloudflare"
                    onChange={(e) => {
                      handleSliders(e)
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
            </div>
            <div className="flex--item3">
              <label className="control-label" htmlFor="cloudflare">
                (Preview) Enable Cloudflare Routing
              </label>
              <fieldset className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={buildData.locals.enable_cloudflare_routing}
                    name="enableCloudflareRouting"
                    id="enable_cloudflare_routing"
                    onChange={(e) => {
                      handleSliders(e)
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </fieldset>
            </div>
          </div>
          <div className="d-flex">
            <div className="flex--item3">
              <button
                className={`s-btn s-btn__primary${isSubmitting ? ' is-loading' : ''}`}
                id="submit"
                type="submit"
                disabled={buttons.submitDisabled || isSubmitting}
                onClick={() => doSubmit({ force: false })}
              >
                Submit
              </button>
              {fqdnError && <p style={{color: 'red'}} className="error"> {fqdnError} </p> }
            </div>
            <div className="flex--item6">
              {submissionResult && (submissionResult.state === 'OK' || submissionResult.state == 'ERROR') &&
                <ApiResponse response={submissionResult.response} />
              }
            </div>
            {/* Warning modal for confirming force resubmits if an instance already appears to exist: */}
            <aside
              className="s-modal s-modal__danger"
              id="modal-base"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              aria-hidden={!(submissionResult && submissionResult.state === 'INSTANCE_CONFLICT')}>
              <div className="s-modal--dialog" role="document">
                <h1 className="s-modal--header" id="modal-title">Warning</h1>
                <p className="s-modal--body" id="modal-description">
                  { submissionResult && submissionResult.message }
                  <br /><br />
                  You can force this instance to be recreated, but <b>only if you have been instructed by the CustX SRE team to do so</b>.
                  Ask in <a href="https://stackexchange.slack.com/archives/CDQ66A56V" target="_blank" rel="noopener" ref={el => el && el.style.setProperty('color', 'var(--theme-secondary-400)', 'important')}>#teams-escalation</a> if you're unsure.
                </p>
                <div className="d-flex gx8 s-modal--footer">
                  <button className="flex--item s-btn s-btn__filled s-btn__danger" type="button" onClick={() => doSubmit({ force: true })}>Force resubmit</button>
                  <button className="flex--item s-btn s-btn__muted" type="button" onClick={() => setSubmissionResult(null)}>Cancel</button>
                </div>
                <button className="s-modal--close s-btn s-btn__muted" type="button" aria-label="Close" onClick={() => setSubmissionResult(null)}>
                  {/* Pasted ClearSm SVG from https://stackoverflow.design/product/resources/icons/ to avoid figuring out pulling in Stacks icons in React */}
                  <svg aria-hidden="true" className="svg-icon iconClearSm native js-svg" width="14" height="14" viewBox="0 0 14 14"><path d="M12 3.41 10.59 2 7 5.59 3.41 2 2 3.41 5.59 7 2 10.59 3.41 12 7 8.41 10.59 12 12 10.59 8.41 7 12 3.41Z"></path></svg>
                </button>
              </div>
            </aside>
          </div>
        </>
      )}
      </Layout>
      <Layout title="Build Queue (Last 10)">
        <ErrorBoundary>
          <TaskQueue api="/v2/infra/tasks/state?tasktype=runbookrun&runbook=FullDeployment" />
        </ErrorBoundary>
      </Layout>
    </>
  )
}
