import * as React from "react"
import { IconButton, useTheme, TablePagination } from "@material-ui/core"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import PropTypes from "prop-types"


export interface PaginationProps  {
    rowsPerPageOptions: (number | { label: string, value: number })[],
    colSpan: number,
    count: number,
    page: number,
    rowsPerPage: number,
    footerStyle: any,
    handleChangePage: EventHandler, 
    handleChangeRowsPerPage: EventHandler
}

type EventHandler = (event: any) => void 

export const HebridesPaginationFooter = (paginationProps: PaginationProps) => {

  const { 
    rowsPerPageOptions, 
    colSpan, 
    count, 
    page, 
    rowsPerPage, 
    footerStyle,
    handleChangePage,
    handleChangeRowsPerPage,  
  } : PaginationProps = paginationProps

  const HebridesPaginationActions = (props: any) => {
    const { count, page, rowsPerPage, onPageChange } = props
    const theme = useTheme();

    const handleFirstPageButtonClick = (event : any) => {
        onPageChange(0)
      }
    
      const handleBackButtonClick = (event : any) => {
        onPageChange(page - 1)
      }
    
      const handleNextButtonClick = (event : any) => {
        onPageChange(page + 1)
      }
    
      const handleLastPageButtonClick = (event : any) => {
        onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1))
      }

      return (
        <div className={footerStyle.root}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      )
  }

  HebridesPaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }

  return (
    <tfoot>
      <tr>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={HebridesPaginationActions}
        />
      </tr>
    </tfoot>
  )

}

export default HebridesPaginationFooter;