import React from "react"

// buildInfo.json should be populated by generateBuildInfo.js, which runs in the CI/CD pipeline as a prebuild script
// "import from .json" is a Webpack feature (json-loader) - not currently supported by vanilla JavaScript
import buildInfo from'../buildInfo.json'

export const Footer = () => {
  return (
    <>
      <footer className="app-footer fc-black-400">
        <div className="app-footer__inner">
          <div>
            <div>
              © <span id="year">{new Date().getUTCFullYear()}</span> Stack Exchange, Inc.
              <br />
            </div>
          </div>
          <div className="app-footer-right d-flex gs12">
            {buildInfo.releaseName &&
              <div className="flex--item">
                <>release: </>
                {buildInfo.releaseUrl
                  ? <a className="fc-theme-secondary-350" href={buildInfo.releaseUrl}>{buildInfo.releaseName}</a>
                  : buildInfo.releaseName
                }
              </div>
            }
            {buildInfo.commitSha &&
              <div className="flex--item">
                <>commit: </>
                {buildInfo.commitUrl
                  ? <a className="fc-theme-secondary-350" href={buildInfo.commitUrl}>{buildInfo.commitSha}</a>
                  : buildInfo.commitSha
                }
              </div>
            }
          </div>
        </div>
      </footer>
    </>
  )
}
