import React, {useEffect} from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { appInsights } from "../../Telemetry/appInsights"

//Import Pages
import { Dashboard } from "./Dashboard"
import { Build } from "../Build/Build"
import { Upgrade } from "../Upgrade/Upgrade"
import { Modify } from "../Modify/Modify"
import { Decomm } from "../Decomm/Decomm"

export const Pages = () => {
  const history = useHistory()

  useEffect(() => {
    trackPageView() // To track the first pageview upon load
    history.listen(trackPageView) // To track the subsequent pageviews
  }, [history])

  const trackPageView = () => {
    appInsights.trackPageView()
  }

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/build" component={Build} />
      <Route exact path="/modify" component={Modify} />
      <Route exact path="/upgrade" component={Upgrade} />
      <Route exact path="/decomm" component={Decomm} />
    </Switch>
  )
}
