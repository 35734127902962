export const uischema = {
  type: "Categorization",
  elements: [
    {
      type: "Category",
      label: "Azure Storage Settings",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope:
                    "#/properties/terraform/properties/backend/properties/azurerm/properties/storage_account_name",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/terraform/properties/backend/properties/azurerm/properties/container_name",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/terraform/properties/backend/properties/azurerm/properties/key",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "Customer Base Config",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/client",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/ticker",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/environment",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/primary_address_prefix",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/secondary_address_prefix",
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/locals/properties/hadr",
                      schema: {
                        const: true,
                      },
                    },
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/fault_domain_count",
                },
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/dns_endpoint",
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      label: "Teams Enabled",
                      scope: "#/properties/locals/properties/teams",
                      rule: {
                        effect: "DISABLE",
                        condition: {
                          scope: "#/properties/locals/properties/teams",
                          schema: {
                            const: true,
                          },
                        },
                      },
                    },
                  ],
                },

                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      label: "(Preview) Enable Cloudlfare",
                      scope: "#/properties/locals/properties/enable_cloudflare",
                      rule: {
                        effect: "DISABLE",
                        condition: {
                          scope: "#/properties/locals/properties/enable_cloudflare_routing",
                          schema: {
                            const: true
                          },
                        },
                      },
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      label: "(Preview) Enable Cloudlfare Routing",
                      scope: "#/properties/locals/properties/enable_cloudflare_routing"
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "SOE Service Config",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/primary_region",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/secondary_region",
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/locals/properties/hadr",
                      schema: {
                        const: true,
                      },
                    },
                  },
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/appgateway_backend_request_timeout",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
                {
                  type: "Control",
                  label: "Web Server Size (default: Standard_B2ms)",
                  scope: "#/properties/locals/properties/web_server_size",
                  rule: {
                    effect: "DISABLE",
                    condition: {},
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/locals/properties/sql_sites_dtus",
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/sql_stack_overflow_dtus",
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/override_sql_password_special_flag",
                },
                {
                  type: "Control",
                  scope:
                    "#/properties/locals/properties/use_random_prefix_secrets",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "Category",
      label: "Application Firewall Rules",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/appgateway_custom_rules",
          options: {
            elementLabelProp: "name",
          },
        },
      ],
    },
    {
      type: "Category",
      label: "API Firewall Rules",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/api_allow_lists",
          options: {
            elementLabelProp: "name",
          },
        },
      ],
    },
    {
      type: "Category",
      label: "SQL Server Firewall Rules",
      elements: [
        {
          type: "Control",
          scope: "#/properties/locals/properties/sql_customer_rules",
          options: {
            elementLabelProp: "name",
          },
        },
      ],
    },
    {
      type: "Category",
      label: "SSL Certificate Data",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              label: "Certificate KeyVault Secret",
              scope: "#/properties/data/properties/azurerm_key_vault_secret/properties/cert-data/properties/name",
              rule: {
                effect: "DISABLE",
                condition: {},
              },
            },
            {
              type: "Control",
              label: "Certificate Password KeyVault Secret",
              scope: "#/properties/data/properties/azurerm_key_vault_secret/properties/cert-pass/properties/name",
              rule: {
                effect: "DISABLE",
                condition: {},
              },
            },
          ],
        },
      ],
    },
  ],
}
