import React from "react"
import { BrowserRouter } from "react-router-dom"

//Import MSAL Auth Components
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"

import {
  UserContextProvider,
  UserMemberOfContextProvider,
} from "./Contexts/UserContext"
import { AppInsightsContextProvider } from "./Contexts/TelemetryContext"

//Import Pages
import MainContent from "./Pages/Main/Main"
import { Pages } from "./Pages/Main/Menu"


const Main = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <AppInsightsContextProvider>
        <UserContextProvider>
          <UserMemberOfContextProvider>
            <BrowserRouter>
              <MainContent>
                <Pages />
              </MainContent>
            </BrowserRouter>
          </UserMemberOfContextProvider>
        </UserContextProvider>
      </AppInsightsContextProvider>
    </MsalAuthenticationTemplate>
  )
}
export default Main
